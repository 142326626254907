<template>
  <tr>
    <!-- NO. -->
    <td>{{ index + 1 }}</td>
    <!-- 품명 -->
    <td class="align_left">
      {{ rowData.itemName }}
    </td>
    <!-- 발주수량 -->
    <td class="align_right">
      {{ rowData.quantity | currency }}
    </td>
    <!-- 단가 -->
    <td class="align_right">{{ rowData.inspectUnitPrice | currency }}</td>
    <!-- 발주금액 -->
    <td class="align_right">{{ rowData.price | currency }}</td>
    <!-- 기납품수량 -->
    <td class="align_right">
      {{ Number(rowData.inspectedAmount) > 0 ? rowData.inspectedAmount : "-" | currency }}
    </td>
    <!-- 납품수량 -->
    <td>
      <InputNumber
        :inputClass="'align_right'"
        :isDisabled="computedDisabledAmounts"
        :maxValue="computedMaxDeliveryQuantity"
        :value.sync="rowData.deliveryQuantity"
      />
    </td>
    <!-- 기납품금액 -->
    <td class="align_right">
      {{ Number(rowData.inspectedPrice) > 0 ? rowData.inspectedPrice : "-" | currency }}
    </td>
    <!-- 납품(지급예정) -->
    <td>
      <InputAutoMoney
        ref="inspectPrice"
        :value.sync="rowData.inspectPrice"
        :inputClass="'align_right'"
        :isDisabled="computedDisabledInspectPrice"
      />
    </td>
    <!-- 품목상세 -->
    <td class="align_left td_ellip">
      {{ rowData.note }}
    </td>
  </tr>
</template>

<script>
import Input from "@/components/common/input/Input";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import SelectBoxQuantityIncludeData from "@/components/common/selectBox/SelectBoxQuantityIncludeData";
import InputNumber from "@/components/common/input/InputNumber";
import SelectBox from "@/components/common/selectBox/SelectBox";
import { getMoneyToNumber, addCommaForMoney } from "@/utils/stringUtils";

export default {
  name: "InspectInfoLineUser",
  components: {
    Input,
    InputAutoMoney,
    SelectBoxQuantityIncludeData,
    SelectBox,
    InputNumber,
  },
  props: {
    rowData: Object,
    index: Number,
  },
  computed: {
    computedMaxDeliveryQuantity() {
      const { quantity, inspectedAmount } = this.rowData;
      let ret = 0;
      if (inspectedAmount) {
        ret = Number(quantity) - Number(inspectedAmount);
      } else {
        ret = Number(quantity);
      }
      return ret.toFixed(2);
    },
    computedDisabledAmounts() {
      let ret = false;
      const quantity = getMoneyToNumber(this.rowData.quantity);
      const inspectedAmount = getMoneyToNumber(this.rowData.inspectedAmount);
      ret = quantity === inspectedAmount;
      return ret;
    },
    computedDisabledInspectPrice() {
      let ret = false;
      const price = getMoneyToNumber(this.rowData.price);
      const inspectedPrice = getMoneyToNumber(this.rowData.inspectedPrice);
      ret = price === inspectedPrice;
      return ret;
    },
  },
  watch: {
    "rowData.deliveryQuantity": function (newVal, oldVal) {
      const { price, quantity, inspectUnitPrice } = this.rowData;
      const calc = Number(inspectUnitPrice) * Number(newVal);
      if (Number(quantity) < Number(newVal)) {
        this.$toast("납품 수량은 발주 수량을 넘을수 없습니다.");
      }

      if (quantity === newVal) {
        this.rowData.inspectPrice = price;
      } else {
        this.rowData.inspectPrice = String(Math.round((_.floor(calc) * 10) / 10));
      }
    },
    "rowData.inspectPrice": function (newVal, oldVal) {
      const inspectPrice = getMoneyToNumber(newVal);
      /** 발주금액 */
      const price = getMoneyToNumber(this.rowData.price);
      /** 기겁수금액 */
      const inspectedPrice = getMoneyToNumber(this.rowData.inspectedPrice);
      if (price < inspectedPrice + inspectPrice) {
        this.$toast("납품(지급예정)은 발주금액을 넘을수 없습니다.");
        this.rowData.inspectPrice = price - inspectedPrice;
        this.$refs.inspectPrice.blur();
      }
    },
  },
};
</script>
