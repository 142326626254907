class InspectFinalPriceModel {
  constructor() {
    this.quantity = "";
    this.totalPrice = "";
    this.sumPrice = "";
    this.note = "";
  }

  setData(objRaw) {
    if (objRaw.quantity) this.quantity = objRaw.quantity;
    if (objRaw.totalPrice) this.totalPrice = objRaw.totalPrice;
    if (objRaw.sumPrice) this.sumPrice = objRaw.sumPrice;
    if (objRaw.note) this.note = objRaw.note;
  }

  getData() {
    const obj = {
      quantity: this.quantity,
      totalPrice: this.totalPrice,
      sumPrice: this.sumPrice,
      note: this.note,
    };

    return obj;
  }

  getAlertMessage() {
    return null;
  }
}

export default InspectFinalPriceModel;
