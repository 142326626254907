<template>
  <TableView>
    <template v-slot:colgroup>
      <col style="width: 192px" />
      <col style="width: 428px" />
      <col style="width: 620px" />
    </template>
    <template v-slot:body>
      <tr>
        <!-- <th>총 납품수량</th>
        <td>
          <InputAutoMoney :value="model.amount | currency" :isDisabled="true" />
        </td> -->
        <td rowspan="2" class="no_padding">
          <dl class="list_total">
            <!-- <dt>소계</dt>
            <dd>
              {{ model.price | currency }}<span class="txt_util">{{ currency }}</span>
            </dd>
            <dt>절사금액</dt>
            <dd>
              (-) {{ cutOffPriceSync | currency
              }}<span class="txt_util">{{ currency }}</span>
            </dd> -->
            <dt class="">검수 금액</dt>
            <dd class="">
              <span class="txt_price"
                >{{ model.sumPrice | currency }}<span class="txt_util">{{ currency }}</span></span
              >
            </dd>
          </dl>
        </td>
      </tr>
    </template>
  </TableView>
</template>

<script>
import Board from "@/components/common/board/Board";
import TableView from "@/components/shared/tableView/TableView";
import Input from "@/components/common/input/Input";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";

import InspectFinalPriceModel from "./InspectFinalPriceModel";

export default {
  name: "SupplyFinalPrice",
  components: {
    Board,
    TableView,
    Input,
    InputAutoMoney,
  },
  props: {
    model: InspectFinalPriceModel,

    cutOffPrice: String,

    currency: String,
    // isDisabledSelectBox: Boolean,
  },
  computed: {
    cutOffPriceSync: {
      get() {
        return this.cutOffPrice;
      },
      set(value) {
        this.$emit("update:cutOffPrice", value);
      },
    },
  },
};
</script>
