<template>
  <fragment>
    <!-- <strong class="tit_util">통화 코드</strong> -->
    <Input
      v-if="isDirectMode"
      class="w90"
      :value.sync="valueSync"
      :isDisabled="isDisabledSelectBox"
    />
    <SelectBox
      class="w90"
      :dataList="currencyList"
      :value.sync="selectedValue"
      :isDisabled="isDisabledSelectBox"
      :placeholder="placeholder"
      @update:value="onChangeSelect"
    />
    <span class="bar_view" />
  </fragment>
</template>

<script>
import SelectBox from "./SelectBox";
import Input from "@/components/common/input/Input";

import { mapState } from "vuex";
import { COMMON_CODE_ACTION } from "@/store/modules/common/action";

export default {
  // tableHead 좌측에 들어가는 직접입력시 tf + 통화코드
  name: "SelectBoxCurrencyNoInputCount",
  components: {
    SelectBox,
    Input,
  },
  props: {
    value: String,
    placeholder: String,
    isDisabledSelectBox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: "KRW",
    };
  },
  mounted() {
    this.$store.dispatch(COMMON_CODE_ACTION.GET_CURRENCY);

    this.setValueByProps();
  },
  computed: {
    ...mapState({
      currencyList: (state) => state.commonCode.currency,
    }),
    isDirectMode() {
      return this.selectedValue === "직접입력";
    },
    currencySync: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    valueSync: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  watch: {
    value(newVal, oldVal) {
      this.setValueByProps();
    },
  },
  methods: {
    onChangeSelect(value) {
      if (this.isDirectMode) {
        this.$emit("update:value", "");
      } else {
        this.$emit("update:value", value);
      }
    },
    setValueByProps() {
      var toSelectedValue;
      switch (this.value) {
        case "KRW":
        case "USD":
        case "EUR":
        case "JPY":
        case "CNY":
          toSelectedValue = this.value;
          break;
        default:
          toSelectedValue = "직접입력";
      }

      if (this.selectedValue !== toSelectedValue) {
        this.selectedValue = toSelectedValue;
      }
    },
  },
};
</script>
