<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">검수 요청</h2>
      </div>
      <div class="body_section">
        <!--발주정보-->
        <InspectOrderInfo :orderCid="model.orderCid" :orderInfo="model.orderInfo" />

        <!-- 발주 총 금액, 검수 후 잔액 -->
        <TableViewWithTitle title="발주 금액 및 검수 잔액">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col />
            <col style="width: 192px" />
            <col />
          </template>
          <template v-slot:body>
            <th>발주 총 금액</th>
            <td class="align_right">
              {{ model.totalPrice | currency }}
              <!-- {{ model.assetTypeName }} -->
            </td>
            <th>검수 후 잔액</th>
            <td class="align_right">
              {{ model.balance | currency }}
              <!-- {{ model.assetTypeName }} -->
            </td>
          </template>
        </TableViewWithTitle>

        <InspectItemInfoUser ref="InspectItemInfo" :dataList.sync="model.inspectItemList" />

        <div class="area_view">
          <TableViewWithTitle title="검수 요청 정보">
            <template v-slot:colgroup>
              <col style="width: 192px" />
              <col />
            </template>
            <template v-slot:body>
              <tr>
                <th>검수내역<span class="ico_purchase ico_star">필수항목</span></th>
                <td>
                  <Input :maxLength="20" :value.sync="model.contentsHistory" />
                </td>
              </tr>
              <tr>
                <th>납품완료일<span class="ico_purchase ico_star">필수항목</span></th>
                <td>
                  <DatePicker :date.sync="model.deliveryCompleteDate" />
                </td>
              </tr>
              <tr>
                <th>검수 증빙 문서<span class="ico_purchase ico_star">필수항목</span></th>
                <td>
                  <FileWrite
                    :dataList="model.attachFileList"
                    @fileUploaded="inspectFileUploaded"
                    @fileRemoved="inspectFileRemoved"
                  />
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
        </div>

        <InspectPriceHistory :dataList="model.degreeSumList ? model.degreeSumList : []" />
        <!-- 검수내역-->
        <InspectHistory :dataList="model.histories" />
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <button type="button" class="btn_primary btn_large" @click="onClickRequest">
          검수요청
        </button>
      </div>
    </Sticky>
    <template v-slot:popup>
      <AlertPopup
        v-if="popup.isShowRequest"
        alertText="검수요청하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseRequest"
        @onClickPrimary="onClickSubmitRequest"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableHead from "@/components/shared/TableHead";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";

import FileWrite from "@/components/common/file/FileWrite.vue";
import FileView from "@/components/common/file/FileView.vue";
import DatePicker from "@/components/common/calendar/DatePicker.vue";
import Sticky from "@/components/layout/content/Sticky";
import AlertPopup from "@/components/layout/popup/AlertPopup";

import InspectContractInfo from "@/components/admin/inspect/write/InspectContractInfo";
import InspectOrderInfo from "@/components/user/inspect/write/InspectOrderInfo";
import InspectHistory from "@/components/admin/inspect/write/InspectHistory";
import InspectPrintPopup from "@/components/admin/inspect/popup/InspectPrintPopup";
import InspectRejectPopup from "@/components/admin/inspect/popup/InspectRejectPopup";
import InspectItemInfoUser from "@/components/admin/inspect/write/InspectItemInfoUser";
import InspectPriceHistory from "@/components/admin/inspect/write/InspectPriceHistory";

import InspectItemModel from "@/components/admin/inspect/write/InspectItemModel.js";

import ApiService from "@/services/ApiService";
// import LocalStorageManager from '@/LocalStorageManager';
import InspectModel from "./InspectModel.js";

import PageMixin from "@/mixins/PageMixin";
import { makePathWithQuery } from "@/utils/urlUtils";

export default {
  name: "InspectWrite",
  components: {
    PageWithLayout,
    TableHead,
    Sticky,
    //Popup,
    TableViewWithTitle,
    //TableView,
    FileWrite,
    FileView,
    Input,
    DatePicker,
    AlertPopup,

    InspectContractInfo,
    InspectOrderInfo,
    InspectHistory,
    InspectPrintPopup,
    InspectRejectPopup,
    InspectItemInfoUser,
    InspectPriceHistory,
  },
  mixins: [PageMixin],
  data() {
    return {
      model: new InspectModel(),
      popup: {
        isShowRequest: false,
      },

      inspectFileList: [],
      inspectItemModel: [],
      // isDateCompareError: false,
      // compareDateErrorText: '',
      // 2차에 적용에정
      // partnerInspectCompleteDate: null,
    };
  },
  computed: {
    // 검수내역 목록
    histories() {
      const { histories } = this.model;

      return histories || [];
    },
    // 최근 검수내역
    latestHistory() {
      if (this.histories.length === 0) return null;

      // 맨 뒤가 현재 상태로 변경.
      // return this.histories[ 0 ];
      return this.histories[this.histories.length - 1];
    },
  },
  created() {
    const { refPk } = this.$route.params;
    this.model.isRefPk = this.$route.meta.isRefPk;
    this.model.docId = refPk;
    this.model.inspectNum = refPk;

    this.initData();
  },
  mounted() {},

  methods: {
    // 데이터 초기화
    async initData() {
      await this.getInspectDetailData();
    },
    // 검수 상세 데이터 가져오기
    async getInspectDetailData() {
      const { inspectNum } = this.model;
      const path = `${this.$apiPath.ST_INSPECT}/history/${inspectNum}`;
      const result = await ApiService.shared.get(path);
      const { code, data, text } = result;
      if (code !== "200") {
        return;
      }
      if (data.stateCode !== "INITIAL") {
        this.$router.push(`${this.$routerPath.INSPECT_VIEW}/${inspectNum}`);
      }
      this.model.setData(data);
      this.model.setOrderInfoData(data);
    },

    // 목록 이동 처리
    gotoList() {
      this.$router.push(this.$routerPath.INSPECT_LIST);
    },
    // 목록이동 버튼
    onClickGoToList() {
      this.gotoList();
    },
    // 검수증빙문서 @fileUploaded
    inspectFileUploaded(files) {
      this.model.attachFileList = this.model.attachFileList.concat(files);
    },
    // 검수증빙문서 @fileRemoved
    inspectFileRemoved(itemToRemove) {
      this.model.attachFileList = this.model.attachFileList.filter((item) => item !== itemToRemove);
    },
    // 검수요청
    onClickRequest() {
      this.popup.isShowRequest = true;
    },
    // 검수요청 레이어 > 취소
    onClickCloseRequest() {
      this.popup.isShowRequest = false;
    },

    // 검수요청 레이어 > 확인
    async onClickSubmitRequest() {
      const { inspectNum } = this.model;
      const obj = this.model.getData();

      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.popup.isShowRequest = false;
        this.alert(alertMessage);
        return;
      }

      const path = `${this.$apiPath.ST_INSPECT}/approve/${inspectNum}`;
      const result = await ApiService.shared.putData(path, obj);
      const { code, data, text } = result;
      this.popup.isShowRequest = false;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.gotoList();
    },
  },
};
</script>
